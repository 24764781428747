import styled from 'styled-components';

import InternalLink from 'components/InternalLink';
import colors from '../../../../../constants/colors';

export const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

export const ActivitiesStats = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  color: ${colors.main.placeholdersAndDisabledBtnsText};
`;

export const SectionLink = styled(InternalLink)`
  text-align: right;

  @media screen and (max-width: 992px) {
    text-align: left;
  }

  @media screen and (min-width: 765px) and (max-width: 992px) and (orientation: portrait) {
    text-align: right;
  }
`;

interface ActivityGridProps {
  withColumns?: boolean;
}

export const ActivityGrid = styled.div<ActivityGridProps>`
  display: grid;
  ${({ withColumns }) =>
    withColumns ? 'grid-template-columns: 1fr 1fr;' : 'grid-template-columns: 1fr;'}
  grid-gap: 24px;
  margin-bottom: 24px;

  @media screen and (max-width: 992px) {
    grid-template-columns: 1fr;
  }
`;

export const EmptyState = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  text-align: center;
  width: 350px;

  @media screen and (max-width: 992px) {
    margin: 0 auto;
    height: calc(100vh - 450px);
    align-items: center;
    flex-direction: column;
    width: 89.3%;
  }

  @media screen and (max-width: 992px) and (orientation: landscape) {
    height: auto;
  }
`;

export const EmptyTitle = styled.div`
  position: relative;
  width: 100%;
`;

export const EmptySubTitle = styled.div`
  position: absolute;
  left: 50%;
  bottom: -50px;
  transform: translateX(-50%);
  width: 100%;
`;
