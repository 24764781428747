import { FC, useEffect, useMemo } from 'react';

import ActivityItem from 'components/Activity';
import { Activity } from 'reducers/activities/types';
import Text from 'components/core/Text';
import Loader from 'components/Loader';

import {
  EmptyState,
  EmptySubTitle,
  EmptyTitle,
  SectionHeader,
  SectionLink,
  ActivityGrid,
  ActivitiesStats,
} from './styled';

interface Props {
  /** Activity list
   * @default []
   */
  activities: Activity[];

  /** Is activity list loading flag
   * @default false
   */
  loading: boolean;

  /** Total count of activities
   * @default 0
   * */
  total?: number;

  /** Total count of programs in activities
   * @default 0
   * */
  totalPrograms?: number;

  /** Total count of sessions in activities
   * @default 0
   * */
  totalSessions?: number;

  /** Show activities in two columns
   * @default false
   * */
  withColumns?: boolean;

  /** Callback for load activities
   *  @default () => {}
   */
  requestFetchActivities?: (data: { search?: string }) => void;
}

const ActivityWidget: FC<Props> = ({
  activities = [],
  loading = true,
  totalPrograms = 0,
  totalSessions = 0,
  withColumns = false,
  requestFetchActivities = () => {},
}) => {
  useEffect(() => {
    requestFetchActivities?.({
      search: '',
    });
  }, [requestFetchActivities]);

  const renderedContent = useMemo(() => {
    const activitiesList = activities?.filter(activity => activity.type === 'workout')?.slice(0, 4);

    return activitiesList.length > 0 ? (
      <ActivityGrid withColumns={withColumns}>
        {activitiesList.map(item => (
          <ActivityItem
            key={item.id}
            id={item.id}
            type={item.type}
            session={item.session}
            program={item.program}
            ease={item.ease}
            quality={item.quality}
            exercisesCount={item.exercisesCount}
            timeLength={item.timeLength}
          />
        ))}
      </ActivityGrid>
    ) : (
      <EmptyState>
        <EmptyTitle>
          <Text variant="promoHeader" color="grayExtraLight" mb={8} isCondensed>
            OOPS!
          </Text>
          <EmptySubTitle>
            <Text variant="display" color="black" transform="uppercase">
              IT LOOKS LIKE YOU HAVEN&apos;T COMPLETED ANY SESSIONS YET
            </Text>
          </EmptySubTitle>
        </EmptyTitle>
        <Text variant="textSmall" color="grayLight">
          As you complete sessions you will see a summary of your activity here.
        </Text>
      </EmptyState>
    );
  }, [activities, withColumns]);

  return (
    <div>
      <SectionHeader>
        <div>
          <Text variant="sectionHeader" transform="uppercase" mb={1}>
            Recent activity
          </Text>
          <ActivitiesStats>
            <Text variant="captionSmall">{totalPrograms} programs</Text>
            <Text variant="captionSmall">{totalSessions} sessions</Text>
          </ActivitiesStats>
        </div>
        {!!activities.length && <SectionLink href="/activity">More</SectionLink>}
      </SectionHeader>
      {loading ? <Loader /> : renderedContent}
    </div>
  );
};

export default ActivityWidget;
