import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// actions
import { requestFetchActivities } from 'actions/activities';
import ActivityWidget from 'components/Pages/Activity/Content/ActivityWidget';

const mapStateToProps = state => {
  return {
    activities: state.activities.all,
    loading: state.activities.loading,
    total: state.activities.total,
    totalPrograms: state.activities.totalPrograms,
    totalSessions: state.activities.totalSessions,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      requestFetchActivities,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ActivityWidget);
