import styled from 'styled-components';
import colors from '../../constants/colors';
import Link from '../Link';

const wrapperStyles = `
  cursor: pointer;
  display: block;
  align-items: center;
  color: ${colors.main.blue};
  transition: all .5s;

  @media screen and (min-width: 1100px) {
    &:hover {
      color: ${colors.main.darkBlue};
    }
  }

  &:active {
    color: ${colors.main.black};
  }
`;

export const NoLinkWrapper = styled.span`
  ${wrapperStyles}
`;

export const LinkWrapper = styled(Link)`
  ${wrapperStyles}
`;
