import { memo } from 'react';
import Head from 'next/head';

const HelpscoutComponent = () => {
  return (
    <Head>
      <script
        type="text/javascript"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: `window.Beacon('init', 'bb1316c1-20ff-4286-b189-a2c159f0fa4a')`,
        }}
      />
    </Head>
  );
};

export default memo(HelpscoutComponent);
