import * as React from 'react';

import { LinkWrapper, NoLinkWrapper, Text } from './styles';

const Index = ({ children, ...props }) => {
  const { href } = props;
  const WrapperByType = href ? LinkWrapper : NoLinkWrapper;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <WrapperByType {...props}>
      <Text>{children}</Text>
      <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 25 25">
        <g transform="translate(-226 -231)">
          <rect width="15" height="15" transform="translate(226 231)" fill="rgba(255,255,255,0)" />
          <path
            d="M14,3V5h3.59L7.76,14.83l1.41,1.41L19,6.41V10h2V3M19,19H5V5h5V3H5A2,2,0,0,0,3,5V19a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2V14H19Z"
            transform="translate(226 232)"
            fill="#0ea0c3"
          />
        </g>
      </svg>
    </WrapperByType>
  );
};

export default Index;
