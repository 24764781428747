import * as React from 'react';

import Text from 'components/core/Text';

import { LinkWrapper, NoLinkWrapper } from './styles';

export default function InternalLink({ children, ...props }) {
  const { href } = props;
  const WrapperByType = href ? LinkWrapper : NoLinkWrapper;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <WrapperByType {...props}>
      <Text variant="textSmall" tag="span" weight="bold" transform="uppercase">
        {children}
      </Text>
    </WrapperByType>
  );
}
