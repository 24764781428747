import React, { useMemo } from 'react';

import Text from 'components/core/Text';
import { Activity as ActivityProps } from 'reducers/activities/types';
import imageUrlGenerator from 'utils/imageUrlGenerator';
import { PONDER_EASE, EASE, QUALITY, PONDER_QUALITY } from '../../constants';
import {
  Wrapper,
  Preview,
  PreviewWrapper,
  Pictogram,
  Metrics,
  ExerciseMetrics,
  ProgramSession,
  ProgramNameWrapper,
  SessionDate,
} from './styles';

const Activity = ({
  type,
  session,
  program,
  ease,
  completedAt,
  quality,
  timeLength,
  exercisesCount,
  compact,
}: ActivityProps) => {
  const iconEasy = ease ? EASE.find(i => i.value === parseInt(`${ease}`, 10))?.icon : '';
  const iconQuality = ease ? QUALITY.find(i => i.value === quality)?.icon : '';
  const programLabel = useMemo(() => {
    if (type === 'programStart') {
      return 'Program started';
    }
    if (type === 'programEnd') {
      return 'Program completed';
    }

    return program?.name || '';
  }, [type, program]);

  const easeLabel = useMemo(() => {
    switch (ease) {
      case 1:
      case 2:
      case 3:
      case 4:
        return PONDER_EASE[ease];
      default:
        return '';
    }
  }, [ease]);

  const qualityLabel = useMemo(() => {
    switch (quality) {
      case 1:
      case 2:
      case 3:
      case 4:
        return PONDER_QUALITY[quality];
      default:
        return '';
    }
  }, [quality]);

  const sessionName = useMemo(() => {
    if (type === 'programStart') return `🏆 You started program ${program?.name}`;
    if (type === 'programEnd') return `🏆 You completed program ${program?.name}`;

    return session?.name || '';
  }, [program?.name, session?.name, type]);

  const sessionDescription = useMemo(
    () =>
      type === 'programStart' || type === 'programEnd'
        ? program?.description
        : session?.description,
    [program?.description, session?.description, type],
  );

  if (!session) return null;

  return (
    <Wrapper href={`/programs/${program.id}/sessions/${session.id}`} compact={compact}>
      <div>
        <ProgramNameWrapper>
          <Text variant="captionSmall">{programLabel}</Text>
          {completedAt && <SessionDate variant="captionSmall">{completedAt}</SessionDate>}
        </ProgramNameWrapper>
        {!compact && (
          <Text
            variant="textSmall"
            tag="h3"
            weight="bold"
            color="black"
            transform="uppercase"
            mb={3}
          >
            {sessionName}
          </Text>
        )}
        <PreviewWrapper>
          <Preview src={imageUrlGenerator(session.picture, '200x200', '100x100')} />
          {type === 'workout' ? (
            <Metrics>
              <Pictogram icon={iconEasy || ''} />
              <Text variant="captionSmall">Ease: {easeLabel}</Text>

              <Pictogram icon={iconQuality || ''} />
              <Text variant="captionSmall">Quality: {qualityLabel}</Text>
            </Metrics>
          ) : (
            <div>
              {compact && (
                <Text
                  variant="textSmall"
                  tag="h3"
                  weight="bold"
                  color="black"
                  transform="uppercase"
                  mb={1}
                >
                  {sessionName}
                </Text>
              )}
              <ProgramSession variant="textSmall" compact={compact}>
                {sessionDescription}
              </ProgramSession>
            </div>
          )}
        </PreviewWrapper>
      </div>
      {type === 'workout' && (
        <ExerciseMetrics>
          <div>
            <Text variant="captionSmall">Minutes</Text>
            <Text variant="mainHeader" tag="p" mb={[0, 4]}>
              {timeLength}
            </Text>
          </div>
          <div>
            <Text variant="captionSmall">moves</Text>
            <Text variant="mainHeader" tag="p">
              {exercisesCount}
            </Text>
          </div>
        </ExerciseMetrics>
      )}
    </Wrapper>
  );
};

export default React.memo(Activity);
